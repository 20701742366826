










































































































import {Component, Mixins, Vue, Watch} from "vue-property-decorator"
import ProductComponent from "@/components/ProductComponent.vue"
import ProductListComponent from "@/components/ProductListComponent.vue"
import ProductService from "@/services/ProductService"
import Product from "@/models/Product"
import Options from "@/models/vue/Options"
import {getModule} from "vuex-module-decorators"
import ProductCategoryService from "@/services/ProductCategoryService"
import ProductCategory from "@/models/ProductCategory"
import LangModule from "@/store/LangModule"
import ProductDialog from "@/components/dialogs/ProductDialog.vue"
import ProductCategoryTool from "@/services/tool/ProductCategoryTool"
import CategoriesTreeviewComponent from "@/components/CategoriesTreeviewComponent.vue"
import CategoryTreeviewComponent from "@/components/CategoryTreeviewComponent.vue"
import CategoryTreeviewModule from "@/store/CategoryTreeviewModule";
import Banner, {BannerReference} from "@/models/Banner";
import BannerService from "@/services/BannerService";
import CarouselComponent from "@/components/CarouselComponent.vue";
import Language from "@/models/Language";
import {mixins} from "vue-class-component";
import PaginationMixin from "@/mixins/PaginationMixin";
import {MultipleItem} from "@/handlers/interfaces/ContentUI";
import Handler from "@/handlers/Handler";
import LoginService from "@/services/LoginService";

@Component({
	components: {CategoriesTreeviewComponent, ProductComponent, ProductListComponent, ProductDialog, CategoryTreeviewComponent, CarouselComponent}
})
export default class ProductsView extends Mixins(PaginationMixin) {

	lang: any = getModule(LangModule).lang
	language = getModule(LangModule).language
	categoryTreeviewModule = getModule(CategoryTreeviewModule)

	order: "cards" | "list" = "cards"
	product: Product = new Product()
	productDialog: boolean = false
	products2: MultipleItem<ProductService> = { items: [], totalItems: 0 }

	get selectedCategories() { return this.categoryTreeviewModule.selectedCategories }
	set selectedCategories(categoryIds: number[]) { this.categoryTreeviewModule.setSelectedCategories(categoryIds) }
	get productCategories() { return this.categoryTreeviewModule.productCategories }

	get itemsPerPage(): number {
		let ipp = 12
		switch (this.$vuetify.breakpoint.name) {

			case ("sm"): {
				ipp = 12
				break
			}

			case ("md"): {
				ipp = 16
				break
			}

			case ("lg"): {
				ipp = 20
				break
			}

			case ("xl"): {
				ipp = 24
				break
			}

		}
		return ipp
	}
	get bannerReference() { return BannerReference }

	loading: boolean = false

	async created() {
		await this.refresh()
	}

	openProductDialog(product: Product) {
		this.productDialog = true
		this.product = product
		this.product.quantity == 0 ? this.product.quantity = 1 : null
	}

	treeToList(tree: ProductCategory) {
		const result = [];

		result.push(tree);
		const children = tree.children || [];
		children.forEach(child => {
			const childResult = this.treeToList(child);
			result.push(...childResult)
		})

		return result;
	}

	@Watch("selectedCategories")
	onSelectedCategoriesChanged() {
		this.refresh()
		this.page = 1
		this.search = ''
	}

	async refresh() {

		if (LoginService.isLoged()) {
			await Handler.getItems(this, this.products2, () =>
				ProductService.getProducts2(this.page - 1, this.size, this.search, this.selectedCategories, null, null, null)
			)
		} else {
			await Handler.getItems(this, this.products2, () =>
				ProductService.getPublicProducts(this.page - 1, this.size, this.search, this.selectedCategories, null)
			)
		}
		this.setPageCount(this.products2.totalItems!!)
	}

	cleanFilter() {
		console.log("Cleaning categories")
		this.categoryTreeviewModule.clear()
		this.$router.replace('/products').catch(() => {
		})
		this.refresh()
	}

	@Watch('page')
	onPageChanged() {
		this.refresh()
	}
}
